<template>
  <div>
    <div class="qr-code-container" v-if="$route.name !== 'certificate_occupancy_doc'">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="qr-header">
              <div class="row">
                <div class="qr-header-logo col-12 col-lg-2">
                  <img src="https://api-ccinspection.asoradev.com/img/logo/MLMUPC.png" class="logo"/>
                </div>
                <div class="qr-header-brand col-12 col-lg-10">
                  <div class="text-center">
                    <h2 class="font-header">ក្រសួងរៀបចំដែនដី នគរូបនីយកម្ម និងសំណង់</h2>
                    <h4 class="font-en">Ministry of Land Management, Urban Planning and Construction</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <router-view/>

    <div style="background-color: #f7f7f7; margin-top: 20px">
      <div class="container-fluid" style="padding-top: 15px;">
        <p class="text-center mb-0">
          © ២០២០ រក្សាសិទ្ធិគ្រប់យ៉ាងដោយ <span class="font-weight-bold">អគ្គនាយកដ្ឋានសំណង់</span>
        </p>
        <p class="text-center mb-0">
          © 2020 Copyright by General Department of Construction
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompanyCertificate',
  metaInfo () {
    return {
      title: 'Certificate of Occupancy',
      titleTemplate: '%s | ' + process.env.VUE_APP_NAME
    }
  }
}
</script>

<style scoped>
  @font-face {
    font-family: "Times New Roman";
    src: local("Times New Roman"), url('./../../../assets/fonts/TimesNewRoman/Times New Roman.ttf') format("truetype");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    font-weight: normal;
  }

  @font-face {
    font-family: "Khmer MEF2";
    src: local("Khmer MEF2"), url('./../../../assets/fonts/KhmerMEF2/KHMERMEF2.ttf') format("truetype");
    unicode-range: U+1780-17FF;
    font-weight: normal;
  }

  @font-face {
    font-family: "Khmer MEF1";
    src: local("Khmer MEF1"), url('./../../../assets/fonts/KhmerMEF1/KHMERMEF1.ttf') format("truetype");
    unicode-range: U+1780-17FF;
    font-weight: normal;
  }

  body {
    padding: 0;
    margin: 0;
    font-family: 'Times New Roman', 'Khmer MEF1', Arial, serif;;
  }

  .font-header {
    font-family: 'Times New Roman', 'Khmer MEF2', Arial, serif;
  }

  p {
    font-family: 'Times New Roman', 'Khmer MEF1', Arial, serif;
  }

  .font-en {
    font-family: 'Times New Roman', Arial, serif;
  }

  .qr-code-container {
    background: #2cacee;
    color: #ffffff;
    padding-top: 20px;
    padding-bottom: 20px;
    width: 100%;
  }

  .qr-header {
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }

  .qr-header-logo {
    text-align: right;
  }

  .qr-header-brand {
    padding-top: 35px;
    padding-right: 135px;
  }

  .logo {
    width: 10rem;
    height: auto;
  }

  @media screen and (max-width: 1200px) {
    .qr-header-brand {
      padding-top: 35px;
      padding-right: 15px;
    }

    .qr-header-logo {
      text-align: center;
    }
  }

  @media screen and (max-width: 767px){
    .qr-header-logo {
      text-align: center;
    }

    .table>tbody>tr>td>p {
      font-size: 14px;
    }

    .label-title {
      line-height: 1.65;
      font-size: 27px;
    }

    .logo {
      width: 5rem;
    }

    .qr-header-brand {
      padding-top: 15px;
    }

    .qr-header-brand div h2 {
      font-size: 22px;
    }

    .qr-header-brand div h4 {
      font-size: 16px;
    }

    .qr-header-brand div h2,
    .qr-header-brand div h4,
    .qr-tips-occupancy .font-header {
      margin-bottom: 0;
    }
  }
</style>
